[data-bs-theme="dark"] {
	--bs-body-bg: #151515;
	--bs-body-color: #ffffff;

	::-webkit-input-placeholder {
		color: #2a2a2a;
	}

	.layout {

		&.industry,
		&.products {
			.card {
				&-header {
					color: inherit;
				}

				&-footer {
					border: none;
				}
			}

			.download {
				.download-text {
					color: inherit;
				}
			}

			.top-solutions-sec {
				background-color: #000;
			}
		}

		.card {
			--bs-card-bg: #000;
		}

		.card-img-wrapper {
			background-color: var(--bs-light);
		}

		.low-em-bg {
			background-color: $secondary;
			color: var(--bs-white);
		}

		.carousel {

			&-control-prev-icon,
			&-control-next-icon {
				filter: none;
			}

			&-caption {
				color: var(--bs-body-color);
			}
		}

		input.form-control {
			color: var(--bs-black);
		}
	}
}